function App() {
  return (
    <div
      style={{
        maxWidth: 800,
        margin: "0 auto",
        padding: 16,
        lineHeight: 1.4,
        fontSize: 20,
      }}
    >
      <div style={{ textAlign: "center" }}>
        <img src="/ModelDepot-logo.png" width="50%" alt="logo"></img>
      </div>
      <br></br>
      ModelDepot has shut down and is no longer hosting Jupyter Notebook guides,
      DeepSearch, or pretrained model files. We really appreciated being able to
      build a community of those willing to share their ML research in an open
      and approachable manner 🙏
      <br />
      <br />
      You can visit similar websites such as{" "}
      <a href="https://gradio.app/" target="_blank" rel="noreferrer">
        Gradio
      </a>{" "}
      or{" "}
      <a href="https://paperswithcode.com/" target="_blank" rel="noreferrer">
        Papers With Code
      </a>
      .
    </div>
  );
}

export default App;
